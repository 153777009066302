import React, {useEffect, useState} from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import './index.css';

function AuthPage() {
  const navigate = useNavigate();
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  useEffect(()=>{
    const authToken = Cookies.get('auth_token');
    if(authToken) navigate('/clients');
  },[])

  const handleLogin = () => {
    if (login === 'admin' && password === 'Qwerty123!') {
      const authToken = 'your_generated_token';
      // Cookies.set('auth_token', authToken);

      // Cookies.set('auth_token', authToken, {
      //   httpOnly: true,
      //   secure: true,
      //   domain: '.abztrainee.com'
      // });
      Cookies.set('auth_token', authToken, {
        domain: '.abztrainee.com'
      });
      navigate('/clients');
    } else {
      setError('Incorrect name or password');
    }
  };

  return (
    <div className="container">
      <h2>Login page</h2>
      <div className="form-group">
        <label className="label">
          Name:
          <input type="text" value={login} onChange={e => setLogin(e.target.value)} className="input" />
        </label>
      </div>
      <div className="form-group">
        <label className="label">
          Password:
          <input type="password" value={password} onChange={e => setPassword(e.target.value)} className="input" />
        </label>
      </div>
      <button onClick={handleLogin} className="button">Login</button>
      {error && <div className="error">{error}</div>}
    </div>
  );
}

export default AuthPage;
