import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import './index.css';

function ClientsPage() {
  const navigate = useNavigate();
  const [clients, setClients] = useState([]);
  const [sortedField, setSortedField] = useState('');
  const [sortOrder, setSortOrder] = useState('');

  useEffect(() => {
    const authToken = Cookies.get('auth_token');

    if (!authToken) {
      navigate('/auth');
    } else {
      // Load clients from localStorage if available
      const storedClients = localStorage.getItem('clients');
      if (storedClients) {
        setClients(JSON.parse(storedClients));
      } else {
        const fakeClients = [
          { id: 1, date: '2024-03-17', firstName: 'Ivan', lastName: 'Ivanov', phoneNumber: '+372-123-456-7890' },
          { id: 2, date: '2024-03-18', firstName: 'Petr', lastName: 'Petrov', phoneNumber: '+372-987-654-3210' },
          { id: 3, date: '2024-03-18', firstName: 'Vasiliy', lastName: 'Vasiliy', phoneNumber: '+372-987-654-3210' },
          { id: 4, date: '2024-03-18', firstName: 'Marina', lastName: 'Marin', phoneNumber: '+372-987-654-3210' },
          { id: 5, date: '2024-03-18', firstName: 'Petr', lastName: 'Petrov', phoneNumber: '+372-987-654-3210' },
          { id: 6, date: '2024-03-18', firstName: 'Petr', lastName: 'Petrov', phoneNumber: '+372-987-654-3210' },
          { id: 7, date: '2024-03-18', firstName: 'Petr', lastName: 'Petrov', phoneNumber: '+372-987-654-3210' },
          { id: 8, date: '2024-03-18', firstName: 'Petr', lastName: 'Petrov', phoneNumber: '+372-987-654-3210' },
          { id: 9, date: '2024-03-18', firstName: 'Petr', lastName: 'Petrov', phoneNumber: '+372-987-654-3210' },
          { id: 10, date: '2024-03-18', firstName: 'Petr', lastName: 'Petrov', phoneNumber: '+372-987-654-3210' },
        ];
        setClients(fakeClients);
      }
    }
  }, [navigate]);

  useEffect(() => {
    if (sortedField && sortOrder) {
      localStorage.setItem('sortedField', sortedField);
      localStorage.setItem('sortOrder', sortOrder);
    }
  }, [sortedField, sortOrder]);

  const handleSort = (field) => {
    if (sortedField === field) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortedField(field);
      setSortOrder('asc');
    }
  };

  const sortedClients = clients.sort((a, b) => {
    const fieldA = a[sortedField] || '';
    const fieldB = b[sortedField] || '';

    if (sortOrder === 'asc') {
      return fieldA.localeCompare(fieldB);
    } else {
      return fieldB.localeCompare(fieldA);
    }
  });

  return (
    <div className="container">
      <h2>Clients page</h2>
      <table className="table">
        <thead>
        <tr>
          <th onClick={() => handleSort('date')}>Update date</th>
          <th onClick={() => handleSort('firstName')}>Name</th>
          <th onClick={() => handleSort('lastName')}>LastName</th>
          <th onClick={() => handleSort('phoneNumber')}>Phone number</th>
        </tr>
        </thead>
        <tbody>
        {sortedClients.map(client => (
          <tr key={client.id}>
            <td>{client.date}</td>
            <td>{client.firstName}</td>
            <td>{client.lastName}</td>
            <td>{client.phoneNumber}</td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  );
}

export default ClientsPage;
